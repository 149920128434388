jQuery(document).ready(function () {


    //Mobile Menu
    jQuery('.menu-trigger').on('click', function () {
        jQuery('body').toggleClass('Menu--open');
    });

    // Product Category Slider
    var productCatSlider = jQuery('.products-category-slider.owl-carousel');
    if (productCatSlider.length) {
        if (productCatSlider.find('.product').length > 1) {
            productCatSlider.owlCarousel({
                items: 3,
                margin: 30,
                nav: true,
                dots: false,
                loop: false,
                navRewind: false,
                autoplay: false,
                smartSpeed: 600,
                animateIn: 'fadeIn',
                animateOut: 'fadeOut',
                touchDrag: false,
                mouseDrag: false,
                navText: '',
                onInitialize: function () {
                    if (productCatSlider.find('.product').length === 1) {
                        this.settings.loop = false;
                        this.settings.nav = false;
                    }
                },
                responsive: {
                    0: {
                        items: 1,
                        touchDrag: true
                    },
                    576: {
                        items: 2,
                        touchDrag: true
                    },
                    767: {
                        items: 3,
                        touchDrag: true
                    }
                }
            });
        } else {
            productCatSlider.show();
        }
    }

    jQuery('.orderby, select:not(#billing_country):not(#shipping_state):not(#calc_shipping_country):not(#calc_shipping_state)').select2();
    jQuery('.product .product-content h2.woocommerce-loop-product__title').matchHeight({
        property: 'min-height',
        byRow: true
    });
    jQuery('.blog-listing .type-post').matchHeight({
        property: 'min-height',
        byRow: true
    });
    jQuery('.site-content-contain .woocommerce-account .woocommerce-Address').matchHeight({
        property: 'min-height',
        byRow: true
    });
    jQuery('.woocommerce-column address').matchHeight({
        property: 'min-height',
        byRow: true
    });
  
    jQuery('.blog-listing .type-post .entry-header').matchHeight({
        property: 'min-height',
        byRow: true
    });
    jQuery('.woocommerce-MyAccount-content .addresses .woocommerce-Address').matchHeight({
        property: 'min-height'
    });

    jQuery('.block-label a.shipping-calculator-button').on('click', function () {
        jQuery(this).parents('.shipping').find('.block-value').toggleClass('open');
    });





});
jQuery(window).on('load',function () {
    setTimeout(function () {
        jQuery('.loader-wrapper').fadeOut();
    }, 500);
});
jQuery(window).resize(function () {
    setTimeout(function () {
        winH = jQuery(window).height();
    });
});

jQuery(document).on("touchstart click", function (e) {
    container = jQuery('.navigation,.signup-sidebar');
    contactInfo = jQuery('.menu-trigger,.login-btn');
    if (!container.is(e.target)
            && container.has(e.target).length === 0 && !contactInfo.is(e.target) && contactInfo.has(e.target).length === 0)
    {
        jQuery('body').removeClass('signup-open');
        jQuery('body').removeClass('menu-open');
        jQuery('.menu-overlay').fadeOut();
    }
});

jQuery(window).scroll(function () {
    var sticky = jQuery('header'),
            scroll = jQuery(window).scrollTop();

    if (scroll >= 500) {
        sticky.addClass('fixed');
    } else {
        sticky.removeClass('fixed');
    }
});
